<template>
  <div class="container">
    <div v-if="error">
      {{ error }}
    </div>
    <div v-else>
      <div class="mb-8 sm:mb-12">
        <router-link
          :to="{
            name: backLinkRouteName,
          }"
          class="inline-flex items-center text-neutral-400 transition-all duration-200 hover:text-neutral-900"
        >
          <IconChevronLeft class="mr-1 h-5 w-5" />
          <span class="text-2xs font-bold uppercase text-neutral-900">
            {{ $t("pages.brandPackagesDetail.back") }}
          </span>
        </router-link>
      </div>
      <div>
        <div
          v-if="assetPackageLoading"
          class="grid grid-cols-1 items-start gap-4 sm:mx-auto lg:grid-cols-12 lg:gap-8"
        >
          <div class="top-8 lg:sticky lg:col-start-1 lg:col-end-4">
            <LoadingIndicator class="mx-auto mt-8 w-8" />
          </div>
          <div class="lg:col-start-4 lg:col-end-13">
            <div class="lg:pl-12">
              <AssetsRowPlaceholder
                v-for="placeholder in placeholders"
                :key="placeholder"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!assetPackageLoading"
          class="grid grid-cols-1 items-start gap-8 sm:mx-auto lg:grid-cols-12 lg:gap-8"
        >
          <div class="top-8 lg:sticky lg:col-start-1 lg:col-end-4">
            <h1 class="mb-4 break-words text-xl font-medium">
              {{ assetPackage.name }}
            </h1>
            <p class="mb-8 break-words text-sm text-neutral-600">
              {{ assetPackage.description }}
            </p>
            <div class="border-t mb-8 border-neutral-100 pt-6">
              <div class="mb-1 flex text-sm">
                <div class="w-1/2 pr-4 font-medium">
                  {{ $t("pages.brandPackagesDetail.category") }}
                </div>
                <div class="w-1/2 text-neutral-600">
                  {{ assetPackage.category.name }}
                </div>
              </div>
              <div class="mb-1 flex text-sm">
                <div class="w-1/2 pr-4 font-medium">
                  {{ $t("pages.brandPackagesDetail.amount") }}
                </div>
                <div class="w-1/2 text-neutral-600">
                  {{ assetPackage.assets.length }} {{ assetText }}
                </div>
              </div>
              <div class="mb-1 flex text-sm">
                <div class="w-1/2 pr-4 font-medium">
                  {{ $t("pages.brandPackagesDetail.size") }}
                </div>
                <div class="w-1/2 text-neutral-600">
                  <span v-if="assetPackage.file">
                    {{ humanReadablePackageSize }}
                  </span>
                  <span v-else>{{
                    $t("pages.brandPackagesDetail.empty")
                  }}</span>
                </div>
              </div>
              <div class="mb-1 flex text-sm">
                <div class="w-1/2 pr-4 font-medium">
                  {{ $t("pages.brandPackagesDetail.downloadBefore") }}
                </div>
                <div class="w-1/2 text-neutral-600">
                  <span>{{ dueDate }}</span>
                </div>
              </div>
            </div>
            <div class="mb-2 hidden sm:block">
              <BaseButton
                v-if="$can('write:assetPackages')"
                size="medium"
                full-width
                variant="outline"
                :text="$t(`pages.brandPackagesDetail.buttonEdit`)"
                class="mr-2"
                @buttonClick="openModalPackageEdit"
              >
                <template #iconLeft>
                  <IconEdit class="text-neutral-300" />
                </template>
              </BaseButton>
            </div>
            <div v-if="assetPackage.file">
              <BaseButton
                size="medium"
                full-width
                :text="$t(`pages.brandPackagesDetail.buttonDownload`)"
                class="mr-2"
                @buttonClick="downloadAssetPackage(assetPackage)"
              >
                <template #iconLeft>
                  <IconDownload class="white" />
                </template>
              </BaseButton>
            </div>
          </div>
          <div class="-mx-5 sm:mx-0 lg:col-start-4 lg:col-end-13">
            <div class="lg:pl-12">
              <AssetsRow
                v-for="asset in assets"
                :key="asset.uuid"
                :asset="asset"
                :menu-items="assetsRowMenuItems(asset)"
                @open="openModalDetails"
              />
              <div v-if="assets.length == 0" class="text-center text-sm">
                {{ $t("pages.brandPackagesDetail.noAssets") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <transition name="fade">
        <AssetsModalDetails
          v-if="modalDetailsOpen"
          :asset="focusedAsset"
          :categories="categories"
          :tags="tags"
          :can-edit="false"
          @clickedTag="navigateToAllAssetsPage"
          @close="closeModal"
        />

        <PackageModalEdit
          v-if="modalPackageEditOpen"
          :categories="categories"
          :roles="roles"
          :asset-package="assetPackage"
          :highlight-disband-button="
            highlightEditModalDisbandButton && !hasHighlightedDisbandButton
          "
          @close="closeModal"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import AssetsModalDetails from "@/components/brand-marketing/assets/AssetsModalDetails";
import PackageModalEdit from "@/components/brand-marketing/packages/PackageModalEdit";
import AssetsRow from "@/components/brand-marketing/assets/AssetsRow";
import AssetsRowPlaceholder from "@/components/brand-marketing/assets/AssetsRowPlaceholder";
import humanReadableBytes from "@/utils/humanReadableBytes";

import FIND_ASSET_PACKAGE from "@/graphql/FindAssetPackage.gql";
import FIND_ASSETS_TAGS from "@/graphql/FindAssetsTags.gql";
import FIND_ASSETS_CATEGORIES from "@/graphql/FindAssetsCategories.gql";
import FIND_ALLOWED_FILE_TYPES from "@/graphql/FindAllowedAssetFileTypes.gql";
import REMOVE_ASSETS_FROM_ASSET_PACKAGE from "@/graphql/RemoveAssetsFromAssetPackage.gql";
import FIND_ROLES from "@/graphql/FindRoles.gql";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "BrandPackagesDetail",

  components: {
    AssetsModalDetails,
    PackageModalEdit,
    AssetsRow,
    AssetsRowPlaceholder,
  },

  props: {
    packageUuid: {
      type: String,
      required: true,
    },
    backLinkRouteName: {
      type: String,
      required: false,
      default: "",
    },
    openEditModal: {
      type: Boolean,
      default: false,
    },
    highlightEditModalDisbandButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: null,
      modalDetailsOpen: false,
      modalPackageEditOpen: false,
      hasHighlightedDisbandButton: false,
      placeholders: 10,
      focusedAssetUuid: undefined,
      formError: null,
      roles: [],
    };
  },

  computed: {
    assetText() {
      if (this.assetPackage.assets.length === 1) {
        return "asset";
      }
      return "assets";
    },
    assetPackageLoading() {
      return this.$apollo.queries.assetPackage.loading;
    },
    focusedAsset() {
      return this.assetPackage.assets.find(
        (asset) => asset.uuid === this.focusedAssetUuid,
      );
    },
    assets() {
      return this.assetPackage.assets;
    },
    humanReadablePackageSize() {
      return humanReadableBytes(this.assetPackage.file.size);
    },
    dueDate() {
      const date = new Date(this.assetPackage.dueDate);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    ...mapGetters("user", ["currentUser"]),
  },

  watch: {
    modalPackageEditOpen: function (newValue) {
      if (newValue === false && !this.hasHighlightedDisbandButton) {
        this.hasHighlightedDisbandButton = true;
      }
    },
  },

  created() {
    if (this.openEditModal) {
      this.openModalPackageEdit();
    }
  },

  apollo: {
    assetPackage: {
      query: FIND_ASSET_PACKAGE,
      variables() {
        return {
          uuid: this.packageUuid,
        };
      },
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    categories: {
      query: FIND_ASSETS_CATEGORIES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    tags: {
      query: FIND_ASSETS_TAGS,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    fileTypes: {
      query: FIND_ALLOWED_FILE_TYPES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },

  methods: {
    ...mapActions("assetPackage", ["downloadAssetPackage"]),
    assetsRowMenuItems(asset) {
      return [
        {
          name: this.$t("pages.brandPackagesDetail.overflowMenu.details"),
          showIf: true,
          callback: () => this.openModalDetails(asset),
        },
        {
          name: this.$t("pages.brandPackagesDetail.overflowMenu.remove"),
          showIf: this.$can("write:assetPackages"),
          destructive: true,
          callback: () => this.removeAssetFromPackage(asset),
        },
      ];
    },
    async fetchRoles() {
      const queryResult = await this.$apollo.query({
        query: FIND_ROLES,
        error(error) {
          this.error = JSON.stringify(error.message);
        },
      });
      this.roles = queryResult.data.roles;
    },
    async openModalPackageEdit() {
      await this.fetchRoles();

      this.modalPackageEditOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    openModalDetails(asset) {
      this.focusedAssetUuid = asset.uuid;
      this.modalDetailsOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    closeModal() {
      this.modalDetailsOpen = false;
      this.modalPackageEditOpen = false;
      document.body.classList.remove("overflow-y-hidden");
    },
    navigateToAllAssetsPage(tag) {
      const tagUuid = tag.uuid;
      this.$router.push({
        name: "brand-marketing-all-assets",
        params: { initialTagsFilter: [tagUuid] },
      });
    },
    removeAssetFromPackage(asset) {
      this.$apollo
        .mutate({
          mutation: REMOVE_ASSETS_FROM_ASSET_PACKAGE,
          variables: {
            uuid: this.assetPackage.uuid,
            assetsToRemove: [asset.uuid],
          },
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message); //@TODO: replace with proper error logging later and remove formError cause it's never rendered
        });
    },
  },
};
</script>
