<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>Edit package</span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <PackageForm
        class="flex-grow"
        :roles="roles"
        :categories="categories"
        :current-asset-package="assetPackage"
        @updateName="updateName"
        @updateCategoryUuid="updateCategoryUuid"
        @updateDescription="updateDescription"
        @updateDueDate="updateDueDate"
        @updateSelectedRoles="updateSelectedRoles"
        @updateIsFeatured="updateIsFeatured"
        @updateCoverImageFileUuid="updateCoverImageFileUuid"
        @updateSortOrder="updateSortOrder"
      />
      <div class="px-8">
        <div
          v-if="formError"
          class="bg-danger-0 mb-6 rounded-sm border-l-2 border-danger-100 px-3 py-2 text-xs font-medium text-danger-500"
        >
          {{ formError }}
        </div>
      </div>

      <ModalSideActions class="justify-between">
        <div class="text-sm text-neutral-900">
          <div v-if="!disbandConfirmationVisible">
            <button
              :class="[
                'focus:outline-none font-medium',
                { 'blink-text-danger': highlightDisbandButton },
              ]"
              @click="showDisbandConfirmation"
            >
              {{ $t("global.disband") }}
            </button>
          </div>
          <div v-else>
            {{ $t("global.areYouSure") }}
            <button
              class="focus:outline-none ml-2 inline-block font-medium"
              @click="disbandPackage"
            >
              {{ $t("global.yes") }}
            </button>
            <button
              class="focus:outline-none ml-2 inline-block font-medium"
              @click="hideDisbandConfirmation"
            >
              {{ $t("global.no") }}
            </button>
          </div>
        </div>
        <div class="flex">
          <BaseButton
            :text="$t(`global.cancel`)"
            size="medium"
            variant="outline"
            @buttonClick="closeModal"
          ></BaseButton>

          <BaseButton
            :text="$t(`global.saveChanges`)"
            size="medium"
            variant="fill"
            class="ml-2"
            @buttonClick="submit"
          >
          </BaseButton>
        </div>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideActions from "@/components/common/ModalSideActions";
import PackageForm from "@/components/brand-marketing/packages/PackageForm";

import UPDATE_ASSET_PACKAGE from "@/graphql/UpdateAssetPackage.gql";
import DELETE_ASSET_PACKAGE from "@/graphql/DeleteAssetPackage.gql";

export default {
  components: {
    ModalSide,
    ModalSideActions,
    PackageForm,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    assetPackage: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: null,
          category: {
            uuid: -1,
          },
          description: null,
          isFeatured: false,
          roles: [],
          coverImage: {
            uuid: null,
          },
          sortOrder: null,
        };
      },
    },
    highlightDisbandButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editedName: this.assetPackage.name,
      editedDescription: this.assetPackage.description,
      editedCategoryUuid: this.assetPackage.category.uuid,
      editedSelectedRoles: this.assetPackage.roles.map((role) => role.id),
      editedDueDate: this.assetPackage.dueDate,
      editedIsFeatured: this.assetPackage.isFeatured,
      editedSortOrder: this.assetPackage.sortOrder,
      editedCoverImageFileUuid: this.assetPackage.coverImage
        ? this.assetPackage.coverImage.uuid
        : this.assetPackage.coverImage,

      formError: null,

      disbandConfirmationVisible: false,
    };
  },

  computed: {
    charactersRemaining() {
      return this.description.length;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
    submit() {
      const description = this.getTrimmedDescriptionOrNull();

      this.$apollo
        .mutate({
          mutation: UPDATE_ASSET_PACKAGE,
          variables: {
            uuid: this.assetPackage.uuid,
            name: this.editedName,
            description: description,
            categoryUuid: this.editedCategoryUuid,
            dueDate: this.editedDueDate,
            roles: this.editedSelectedRoles,
            isFeatured: this.editedIsFeatured,
            coverImageFileUuid: this.editedCoverImageFileUuid,
            sortOrder: this.editedSortOrder,
          },
        })
        .then(() => {
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message);
        });
    },
    updateName(assetPackageName) {
      this.editedName = assetPackageName;
    },
    updateDescription(assetPackageDescription) {
      this.editedDescription = assetPackageDescription;
    },
    updateCategoryUuid(assetPackageCategoryUuid) {
      this.editedCategoryUuid = assetPackageCategoryUuid;
    },
    updateSelectedRoles(assetPackageRoles) {
      this.editedSelectedRoles = assetPackageRoles;
    },
    updateDueDate(assetPackageDueDate) {
      this.editedDueDate = assetPackageDueDate;
    },
    updateIsFeatured(assetPackageIsFeatured) {
      this.editedIsFeatured = assetPackageIsFeatured;
    },
    updateCoverImageFileUuid(assetPackageCoverImageFileUuid) {
      this.editedCoverImageFileUuid = assetPackageCoverImageFileUuid;
    },
    updateSortOrder(assetPackageSortOrder) {
      this.editedSortOrder = assetPackageSortOrder;
    },
    getTrimmedDescriptionOrNull() {
      if (this.editedDescription === null) {
        return null;
      }

      return this.editedDescription.trim() !== ""
        ? this.editedDescription.trim()
        : null;
    },
    showDisbandConfirmation() {
      this.disbandConfirmationVisible = true;
    },
    hideDisbandConfirmation() {
      this.disbandConfirmationVisible = false;
    },
    disbandPackage() {
      this.$apollo
        .mutate({
          mutation: DELETE_ASSET_PACKAGE,
          variables: {
            uuid: this.assetPackage.uuid,
          },
        })
        .then(() => {
          this.$router.push({ name: "brand-marketing-packages" });
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message); //@TODO: replace with proper error logging later and remove formError cause it's never rendered
        });
    },
  },
};
</script>

<style scoped>
@keyframes blinkTextDanger {
  40%,
  60% {
    @apply text-danger-600;
  }
}
.blink-text-danger {
  animation: 0.8s blinkTextDanger 2;
}
</style>
